import React, { useEffect, useState } from "react";

import { Button, Col, Row, Table } from "reactstrap";
import { formatTimeStampToDateAndTime } from "../../utils/helper";

import ReviewModal from "./ReviewModal";

const FormModalChecks = ({ person, addCheck, openReviewModal }) => {
  const [showReviewModal, setShowReviewModal] = useState(false);

  const onAddCheck = (name, date) => {
    setShowReviewModal(false);
    addCheck(name, date);
  };

  useEffect(() => {
    if (openReviewModal) {
      setShowReviewModal(true);
    }
  }, [openReviewModal]);

  return (
    <Row>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>Überprüfungen</h5>
        <Button onClick={() => setShowReviewModal(true)} size="sm">
          +
        </Button>
      </div>
      <Col>
        {person.checks.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th>Überprüft am</th>
                <th>Überprüft von</th>
              </tr>
            </thead>
            <tbody>
              {person.checks.map((check, index) => (
                <tr key={index}>
                  <td>{formatTimeStampToDateAndTime(check.date)}</td>
                  <td>{check.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Col>

      <ReviewModal
        isOpen={showReviewModal}
        toggle={() => setShowReviewModal(false)}
        onAdd={(name, date) => onAddCheck(name, date)}
      />
    </Row>
  );
};

export default FormModalChecks;
