import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";

import { PDFDownloadLink } from "@react-pdf/renderer";
import Fahrerkarte from "../../utils/Fahrerkarte";
import FormModalInputs from "./FormModalInputs";
import FormModalInstructions from "./FormModalInstructions";
import FormModalChecks from "./FormModalChecks";

import { formatDateString } from "../../utils/helper";
import FormModalUploads from "./FormModalUploads";

const FormModal = ({
  show,
  selectedPerson,
  save,
  remove,
  reset,
  fastCheck,
  toggle,
}) => {
  const [reviewModal, setReviewModal] = useState(false);
  const [formData, setFormData] = useState([]);
  const [input, setInput] = useState(() => ({
    id: "",
    lastname: "",
    firstname: "",
    street: "",
    city: "57548 Kirchen",
    validFrom: "",
    validUntil: "",
    licenceNumber: "",
    issuingAuthority: "Kreisverwaltung Altenkirchen",
    classes: [],
    restrictions: "",
    medicalCheckup: "",
    vehicles: [],
    checks: [],
  }));

  const buttonRef = useRef(null);

  useEffect(() => {
    if (input) {
      const classesValue = input.classes.join(", ");

      const updatedFormData = [
        { label: "lastname", value: input.lastname },
        { label: "firstname", value: input.firstname },
        { label: "street", value: input.street },
        { label: "city", value: input.city },
        {
          label: "validFrom",
          value: formatDateString(input.validFrom),
        },
        {
          label: "validUntil",
          value: input.validUntil ? formatDateString(input.validUntil) : "---",
        },
        { label: "licenceNumber", value: input.licenceNumber },
        { label: "issuingAuthority", value: input.issuingAuthority },
        { label: "classes", value: classesValue },
        { label: "restrictions", value: input.resetrictions },
        {
          label: "reviewer",
          value: input.checks[0]?.name || "Wehrführung",
        },
      ];

      setFormData(updatedFormData);
    }
  }, [input]);

  useEffect(() => {
    if (selectedPerson) {
      setInput(selectedPerson);
    }
  }, [selectedPerson]);

  useEffect(() => {
    if (fastCheck) {
      setReviewModal(true);
    }
  }, [fastCheck]);

  const handleInputChange = (name, value) => {
    setInput((prevInput) => ({
      ...prevInput,
      [name]: value,
    }));
  };
  const handleClassChange = useCallback(
    (classValue) => {
      let updatedClasses = [...input.classes];

      const addClassIfNotExists = (classToAdd) => {
        if (!updatedClasses.includes(classToAdd)) {
          updatedClasses.push(classToAdd);
        }
      };

      const removeClassIfExists = (classToRemove) => {
        updatedClasses = updatedClasses.filter((c) => c !== classToRemove);
      };

      switch (classValue) {
        case "B":
          updatedClasses = updatedClasses.includes("B") ? [] : ["B"];
          break;

        case "BE":
          if (!updatedClasses.includes("BE")) {
            updatedClasses.push("BE");
            addClassIfNotExists("B");
          } else {
            removeClassIfExists("BE");
            removeClassIfExists("C1E");
            removeClassIfExists("CE");
          }
          break;

        case "C1":
          if (!updatedClasses.includes("C1")) {
            updatedClasses.push("C1");
            addClassIfNotExists("B");
          } else {
            removeClassIfExists("C1");
            removeClassIfExists("C1E");
            removeClassIfExists("C");
          }
          break;

        case "C1E":
          if (!updatedClasses.includes("C1E")) {
            updatedClasses.push("C1E");
            addClassIfNotExists("C1");
            addClassIfNotExists("BE");
            addClassIfNotExists("B");
          } else {
            removeClassIfExists("C1E");
            removeClassIfExists("CE");
          }
          break;

        case "C":
          if (!updatedClasses.includes("C")) {
            updatedClasses.push("C");
            addClassIfNotExists("B");
            addClassIfNotExists("C1");
          } else {
            removeClassIfExists("C");
          }
          break;

        case "CE":
          if (!updatedClasses.includes("CE")) {
            updatedClasses.push("CE");
            addClassIfNotExists("C");
            addClassIfNotExists("C1E");
            addClassIfNotExists("C1");
            addClassIfNotExists("BE");
            addClassIfNotExists("B");
          } else {
            removeClassIfExists("CE");
          }
          break;

        case "FW-Führerschein":
          if (!updatedClasses.includes("FW-Führerschein")) {
            updatedClasses.push("FW-Führerschein");
            addClassIfNotExists("B");
          } else {
            removeClassIfExists("FW-Führerschein");
          }
          break;

        default:
          break;
      }

      setInput((prevInput) => ({
        ...prevInput,
        classes: updatedClasses,
      }));
    },
    [input, setInput]
  );

  const addVehicle = (vehicle, date, instructor) => {
    if (vehicle && date && instructor) {
      setInput((prevInput) => ({
        ...prevInput,
        vehicles: [
          ...prevInput.vehicles,
          {
            vehicle,
            date,
            instructor,
          },
        ],
      }));
    }
  };

  const removeVehicle = useCallback((index) => {
    setInput((prevInput) => ({
      ...prevInput,
      vehicles: prevInput.vehicles.filter((_, idx) => idx !== index),
    }));
  }, []);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (input.checks.length > 0) {
        save(input);
      } else {
        setReviewModal(true);
      }
    },
    [input, save]
  );

  const addCheck = (name, date) => {
    if (fastCheck) {
      buttonRef.current.focus();
    }

    const newCheck = {
      date,
      name,
    };

    setInput((prevInput) => ({
      ...prevInput,
      checks: [...prevInput.checks, newCheck],
    }));
  };

  return (
    <Modal
      fade={fastCheck ? false : true}
      isOpen={show}
      toggle={toggle}
      size="xl"
    >
      <Form onSubmit={onSubmit}>
        <ModalHeader toggle={toggle}>Fahrerinformationen</ModalHeader>
        <ModalBody>
          <FormModalInputs
            person={input}
            onChangeInput={handleInputChange}
            onChangeClass={handleClassChange}
            onRemove={remove}
          />
          <FormModalInstructions
            person={input}
            addVehicle={addVehicle}
            removeVehicle={removeVehicle}
          />
          <FormModalChecks
            person={input}
            addCheck={addCheck}
            fastCheck={fastCheck}
            openReviewModal={reviewModal}
          />
          <FormModalUploads driverID={input.id} />
        </ModalBody>
        <ModalFooter>
          <Button innerRef={buttonRef} type="submit" color="primary">
            Speichern
          </Button>
          {input.checks.length > 0 && (
            <Button color="secondary">
              <PDFDownloadLink
                document={<Fahrerkarte formData={formData} />}
                fileName={`${input.firstname} ${input.lastname} - Fahrerstammkarte `}
                style={{ textDecoration: "none", color: "white" }}
              >
                {({ loading }) =>
                  loading ? "Lade das PDF herunter..." : "PDF herunterladen"
                }
              </PDFDownloadLink>
            </Button>
          )}
          <Button color="danger" onClick={reset}>
            Abbrechen
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default FormModal;
