import { Table } from "reactstrap";

import {
  isTimestampMoreThanMonthsAgo,
  formatTimeStampToDateAndTime,
} from "../utils/helper";

const LicenceTable = ({ data, clickTableRow }) => {
  return (
    <Table striped responsive hover>
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Führerscheinnummer</th>
          <th className="hide-on-small">Führerscheinklassen</th>
          <th>Gültig bis</th>
          <th>Letzte Überprüfung</th>
        </tr>
      </thead>
      <tbody>
        {data
          .sort((a, b) => a.lastname.localeCompare(b.lastname))
          .map((item, index) => {
            const checkIndex = item.checks.length - 1;
            const latestCheck = item.checks[checkIndex];
            const today = new Date();
            const dateInThreeMonths = new Date(today);
            dateInThreeMonths.setMonth(dateInThreeMonths.getMonth() + 3);

            const validUntil = item.validUntil
              ? new Date(item.validUntil)
              : null;
            const medicalCheckup = item.medicalCheckup
              ? new Date(item.medicalCheckup)
              : null;

            const licenceIsValid = !validUntil || validUntil > today;
            const medicalCheckupIsValid =
              !medicalCheckup || medicalCheckup > today;

            const licenseIsExpiringSoon =
              validUntil && validUntil <= dateInThreeMonths;
            const medicalCheckupIsExpiringSoon =
              medicalCheckup && medicalCheckup <= dateInThreeMonths;

            const isYellow =
              (licenceIsValid && licenseIsExpiringSoon) ||
              (medicalCheckupIsValid && medicalCheckupIsExpiringSoon) ||
              (licenceIsValid &&
                latestCheck &&
                isTimestampMoreThanMonthsAgo(latestCheck.date, 6) &&
                !isTimestampMoreThanMonthsAgo(latestCheck.date, 12));

            const isRed =
              !licenceIsValid ||
              !medicalCheckupIsValid ||
              !latestCheck ||
              isTimestampMoreThanMonthsAgo(latestCheck.date, 12);

            let expirationDate = "---";

            if (validUntil && medicalCheckup) {
              expirationDate =
                validUntil < medicalCheckup ? validUntil : medicalCheckup;
            } else if (validUntil) {
              expirationDate = validUntil;
            } else if (medicalCheckup) {
              expirationDate = medicalCheckup;
            }

            const formattedExpirationDate =
              expirationDate instanceof Date
                ? expirationDate.toLocaleDateString()
                : "---";

            return (
              <tr key={index} onClick={() => clickTableRow(item, false)}>
                <td>
                  <div
                    className={`led ${
                      isYellow ? "led-yellow" : isRed ? "led-red" : "led-green"
                    }`}
                  />
                </td>
                <td>{`${item.lastname}, ${item.firstname}`}</td>
                <td>{item.licenceNumber}</td>
                <td className="hide-on-small">
                  {item.classes.sort().join(", ")}
                </td>
                <td
                  style={{
                    color:
                      (licenseIsExpiringSoon && licenceIsValid) ||
                      (medicalCheckupIsExpiringSoon && medicalCheckupIsValid)
                        ? "orange"
                        : !licenceIsValid || !medicalCheckupIsValid
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formattedExpirationDate}
                </td>
                <td
                  style={{
                    color: isTimestampMoreThanMonthsAgo(latestCheck.date, 12)
                      ? "red"
                      : isTimestampMoreThanMonthsAgo(latestCheck.date, 9) &&
                        !isTimestampMoreThanMonthsAgo(latestCheck.date, 12)
                      ? "orange"
                      : "inherit",
                  }}
                  onClick={() => clickTableRow(item, true)}
                >
                  {latestCheck
                    ? formatTimeStampToDateAndTime(latestCheck.date)
                    : "---"}
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default LicenceTable;
