import React from "react";
import { Modal, ModalHeader, ModalBody, List } from "reactstrap";
function InfoModal({ isOpen, toggle }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Versionshinweise</ModalHeader>
      <ModalBody>
        <List>
          <li>
            <span className="font-weight-bold">Version 1.0.3 (29.02.2024)</span>
            <ul>
              <li>Header etwas verkleinert</li>
              <li>
                Footer ist nicht mehr fixed, wenn der Content größer wird.
              </li>
            </ul>
          </li>
          <li>
            <span className="font-weight-bold">Version 1.0.2 (23.02.2024)</span>
            <ul>
              <li>Datum der letzten Überprüfung kann jetzt angepasst werden</li>
            </ul>
          </li>
          <li>
            <span className="font-weight-bold">Version 1.0.1 (22.02.2024)</span>
            <ul>
              <li>Datenschutzanpassungen</li>
              <li>Analytics deaktiviert</li>
              <li>Google Fonts CDN auf Lokal geändert</li>
              <li>Bootstrap von CDN auf Lokal geändert</li>
            </ul>
          </li>
          <li>
            <span className="font-weight-bold">Version 1.0 (22.02.2024)</span>
            <ul>
              <li>Erster öffentlicher Release</li>
            </ul>
          </li>
        </List>
      </ModalBody>
    </Modal>
  );
}

export default InfoModal;
