import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import header from "../assets/img/header.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 30,
  },
  mainTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: "red",
    textAlign: "center",
    marginTop: -15,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 15,
    color: "#333", // Ändere die Farbe der Subtitles
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    paddingBottom: 5,
  },
  content: {
    marginTop: 10,
    fontSize: 14,
  },
  section: {
    marginBottom: 20,
    backgroundColor: "#F2F2F2",
    padding: 10,
    borderRadius: 5,
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  label: {
    fontWeight: "bold",
    marginBottom: 5,
  },
  value: {
    marginBottom: 10,
  },
  contentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    alignItems: "center",
  },
  headline: {
    fontWeight: "bold",
    width: "40%",
  },
  text: {
    width: "55%",
  },
  rules: {
    fontSize: 12,
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    maxWidth: "15%",
    marginRight: 10,
  },
  checkbox: {
    width: 15,
    height: 15,
    marginRight: 8,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: "#000",
  },
  listItem: {
    fontSize: 12,
    marginBottom: 5,
  },
  signatureArea: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  signature: {
    alignItems: "flex-start",
    width: "45%",
  },
  signatureLine: {
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    marginTop: 10,
  },
  signatureText: {
    marginTop: 5,
    fontSize: 12,
  },
});

const Fahrerkarte = ({ formData }) => {
  const data = formData.reduce((obj, item) => {
    obj[item.label.replace(/\s+/g, "")] = item.value;
    return obj;
  }, {});

  const availableClasses = [
    "B",
    "BE",
    "C1",
    "C1E",
    "C",
    "CE",
    "Feuerwehrführerschein",
  ];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image
            cache={false}
            src={header}
            onError={(error) =>
              console.error("Fehler beim Laden des Bildes:", error)
            }
          />
        </View>
        <Text style={styles.mainTitle}>Fahrerstammkarte</Text>
        <View style={styles.content}>
          <Text style={styles.subTitle}>Persönliche Angaben</Text>
          <View style={styles.contentRow}>
            <Text style={styles.headline}>Name:</Text>
            <Text
              style={styles.text}
            >{`${data.lastname}, ${data.firstname} `}</Text>
          </View>
          <View style={styles.contentRow}>
            <Text style={styles.headline}>Anschrift:</Text>
            <Text style={styles.text}>{`${data.street}, ${data.city}`}</Text>
          </View>

          <Text style={[styles.subTitle, { marginTop: 10 }]}>
            Angaben zum Führerschein
          </Text>

          <View style={styles.contentRow}>
            <Text style={styles.headline}>Führerscheinnummer:</Text>
            <Text style={styles.text}>{data.licenceNumber}</Text>
          </View>
          <View style={styles.contentRow}>
            <Text style={styles.headline}>Ausstellende Behörde:</Text>
            <Text style={styles.text}>{data.issuingAuthority}</Text>
          </View>
          <View style={styles.contentRow}>
            <Text style={styles.headline}>Gültig von:</Text>
            <Text style={styles.text}>{data.validFrom}</Text>
          </View>
          <View style={styles.contentRow}>
            <Text style={styles.headline}>Gültig bis:</Text>
            <Text style={styles.text}>
              {data.validUntil ? data.validUntil : "---"}
            </Text>
          </View>
          <View style={styles.contentRow}>
            <Text style={styles.headline}>
              Einschränkungen (Schlüsselzahl):
            </Text>
            <Text style={styles.text}>
              {data.restrictions ? data.restrictions : "---"}
            </Text>
          </View>

          <Text style={[styles.subTitle, { marginTop: 10, marginBottom: 15 }]}>
            Klassen:
          </Text>

          <View style={styles.section}>
            {availableClasses.map((className, index) => (
              <View style={styles.checkboxContainer} key={index}>
                <View
                  style={[
                    styles.checkbox,
                    {
                      backgroundColor: data.classes.includes(className)
                        ? "#000"
                        : "#FFF",
                    },
                  ]}
                />
                <Text style={[styles.headline, { fontSize: 12 }]}>
                  {className}
                </Text>
              </View>
            ))}
          </View>

          <View>
            <Text style={styles.subTitle}>Regeln:</Text>
            <View>
              <Text style={styles.listItem}>
                • Es dürfen nur Fahrzeuge geführt werden, für die die
                erforderliche Fahrerlaubnis vorhanden ist und eine
                Einweisungsfahrt absolviert wurde.
              </Text>
              <Text style={styles.listItem}>
                • Jegliche Änderungen oder der Entzug der Fahrerlaubnis,
                einschließlich einzelner Klassenänderungen, sind umgehend und
                unaufgefordert der Wehrführung zu melden.
              </Text>
              <Text style={styles.listItem}>
                • Sämtliche im Führerschein angegebene Einschränkungen (bspw.
                Sehhilfe) sind zu berücksichtigen.
              </Text>
              <Text style={styles.listItem}>
                • Es dürfen keine Fahrzeuge unter dem Einfluss von Drogen oder
                Alkohol geführt werden. Gleiches gilt für den Einfluss von
                Medikamenten, die die sichere Führung eines Fahrzeuges
                beeinträchtigen.
              </Text>
            </View>
            <Text style={[styles.subTitle, { marginTop: 10 }]}>{``}</Text>

            <View style={styles.signatureArea}>
              <View style={styles.signature}>
                <Text style={styles.signatureText}>
                  Kirchen, {new Date().toLocaleDateString("de-DE")}
                </Text>
                <View style={styles.signatureLine} />
                <Text
                  style={styles.signatureText}
                >{`${data.firstname} ${data.lastname}`}</Text>
              </View>
              <View style={styles.signature}>
                <Text style={styles.signatureText}>
                  Kirchen, {new Date().toLocaleDateString("de-DE")}
                </Text>
                <View style={styles.signatureLine} />
                <Text
                  style={styles.signatureText}
                >{`${data.reviewer}, Führungskraft`}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Fahrerkarte;
